import React, { useEffect } from "react"
import { Button, CircularProgress, Container, Typography } from "@mui/material"

import { navigate } from "gatsby"
import successfulPaymentSVG from "../../images/success-payment.svg"
import useSearchParam from "react-use/lib/useSearchParam"

const SUCCESS_DELAY_TIME_IN_MS = 6000

export const SuccessfulPaymentPage = () => {
  const redirectUrl = useSearchParam("redirectUrl")

  useEffect(() => {
    if (!redirectUrl) return

    const timer = setTimeout(() => {
      navigate(redirectUrl ? redirectUrl : "/")
    }, SUCCESS_DELAY_TIME_IN_MS)

    return () => {
      clearTimeout(timer)
    }
  }, [redirectUrl])

  return (
    <Container
      sx={{
        margin: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        textAlign: "center",
      }}
    >
      <img src={successfulPaymentSVG} />
      <Typography variant="h2">Successful Payment!</Typography>

      <Typography variant="body1">
        We're processing your dashboard. Please wait to proceed
      </Typography>

      <Button startIcon={<CircularProgress style={{ color: "white" }} size={20} />}>
        Loading...
      </Button>
    </Container>
  )
}
